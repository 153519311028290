import React from 'react';
import PropTypes from 'prop-types';

export const OperatorSection = ({ operator, contact }) => {
  const {
    title,
    subtitle,
    operator: operatorSection,
    address: operatorAddress
  } = operator;
  const { title: contactTitle, telephone, fax, email } = contact;

  return (
    <div className="flex md:space-x-4 md:flex-row flex-col space-y-4 md:space-y-0 text-black-200">
      <div className="p-4 sm:p-8 shadow-100 md:w-3/4 space-y-2">
        <h2 className="card-title">{title}</h2>
        <p className="card-text">{subtitle}</p>
        <div>
          <p className="card-text">{operatorSection.title}</p>
          <p className="card-text">{operatorSection.text}</p>
        </div>
        <div>
          <p className="card-text">{operatorAddress.street}</p>
          <p className="card-text">{operatorAddress.place}</p>
        </div>
      </div>
      <div className="shadow-100 md:w-1/4 p-4 sm:p-8 space-y-2">
        <h2 className="card-title">{contactTitle}</h2>
        <div>
          <p className="card-text">{telephone}</p>
          <p className="card-text">{fax}</p>
          <p className="card-text">{email}</p>
        </div>
      </div>
    </div>
  );
};

OperatorSection.propTypes = {
  operator: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    operator: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      place: PropTypes.string.isRequired
    })
  }),
  contact: PropTypes.shape({
    title: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired
};

export const ResponsibleSection = ({ title, name, street, place }) => (
  <div className="shadow-100 p-4 sm:p-8 card-text text-black-200 space-y-2">
    <h2 className="card-title font-normal">{title}</h2>
    <div>
      <p>{name}</p>
      <p>{street}</p>
      <p>{place}</p>
    </div>
  </div>
);

ResponsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired
};

export const DoubleCard = ({ left, right }) => {
  const { title: leftTitle, text: leftText } = left;
  const { title: rightTitle, text: rightText } = right;

  return (
    <div className="flex md:space-x-4 text-black-200 md:flex-row flex-col space-x-0 space-y-4 md:space-y-0">
      <div className="shadow-100 w-full md:w-1/2 p-4 sm:p-8 md:card-title card-title-sm space-y-2">
        <h2>{leftTitle}</h2>
        <p
          className="card-text break-words"
          dangerouslySetInnerHTML={{ __html: leftText }}
        />
      </div>
      <div className="shadow-100 w-full md:w-1/2 p-4 sm:p-8 md:card-title card-title-sm space-y-2">
        <h2>{rightTitle}</h2>
        <p
          className="card-text"
          dangerouslySetInnerHTML={{ __html: rightText }}
        />
      </div>
    </div>
  );
};

DoubleCard.propTypes = {
  left: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  right: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export const WideCard = ({ text, title }) => (
  <div className="shadow-100 p-4 sm:p-8 text-black-200 space-y-2">
    <h2 className="card-title">{title}</h2>
    <p className="card-text" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

WideCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
