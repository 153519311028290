import { graphql } from 'gatsby';

import Imprint from './template';

export const pageQuery = graphql`
  query Imprint($language: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "Imprint" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        pageTitle
        header {
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 6500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        titleSection {
          title
          subtitle
        }
        operator {
          title
          subtitle
          operator {
            title
            text
          }
          address {
            street
            place
          }
        }
        contact {
          title
          telephone
          fax
          email
        }
        responsible {
          title
          name
          street
          place
        }
        euSettlement {
          title
          text
        }
        universalSettlement {
          title
          text
        }
        copyright {
          title
          text
        }
        liabilityForContent {
          title
          text
        }
        liabilityForLinks {
          title
          text
        }
      }
    }
  }
`;

export default Imprint;
